import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
} from "formik";
import { useRouter } from "next/router";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import useSWR, { KeyedMutator } from "swr";
import { useCurrentUser } from "./AuthProvider";
import { GLOBAL_GROUP_KEY } from "./Utils/constant";

import { RadioGroup } from "@headlessui/react";
import { CheckIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { addMonths, format } from "date-fns";
import Dropzone from "react-dropzone";
import {
  createOpportunity,
  getLinkedDeals,
  getOpportunity,
  getOrganization,
  getter,
  updateOpportunity,
} from "../api";
import {
  AutocompleteResult,
  GTMProjectDetailResponse,
  OpportunityDetail,
  OpportunityType,
  OrganizationAutocompleteResult,
  OrganizationDetail,
  PaginatedList,
  PortfolioOrganizationItem,
  PydanticError,
  Success,
} from "../api/types";
import ErrorModal from "./ErrorModal";
import FileThumbnail from "./FileThumbnail";
import FormCompanySelectField from "./Form/FormCompanySelectField";
import FormPersonSelectField from "./Form/FormPersonSelectField";
import FormTextAreaField from "./Form/FormTextAreaField";
import FormTextField from "./Form/FormTextField";
import FormUserSelectField from "./Form/FormUserSelectField";
import VisibilityFieldForOpportunity from "./Form/VisibilityFieldForOpportunity";
import { useOpportunity } from "./Hook/useOpportunity";
import NewModal from "./NewModal";
import { OpportunityLinkedDeal } from "./Opportunity/OpportunityLinkedDeal";
import {
  AdditionalDropdown,
  AdditionalRadioGroup,
  AdditionalSubSector,
  AdditionalTextArea,
  AdditionalTextField,
} from "./OpportunityForm/OpportunityFormComponents";
import {
  AdditionalComponent,
  AdditionalComponents,
  FieldError,
  INVESTMENT_OPPORTUNITY_TYPE,
  OPPORTUNITY_FORM,
  OpportunityDescription,
  OpportunityFormValues,
  Sector,
  additionalValidation,
  emptyFormValue,
  generateDomainFromCompanyName,
  getDefaultOppType,
  getLinkedDealsFromOppType,
  getOpportunityType,
  getPreSelectedAddtionalFields,
  mandatoryFields,
  opportunityToFormValues,
  radioFields,
  setOpportunityTypeInSession,
  showLinkedDeals,
  updateFieldValue,
  updateLinkedDealsFormValues,
  updateNameAndDescriptionValues,
  updateOpportunityInSession,
  updatePreDescription,
  vvhcMandatoryFields,
  vvseaiMandatoryFields,
} from "./OpportunityForm/OpportunityFormUtils";
import PortfolioForm from "./PortfolioForm";
import AutocompleteMultiselect from "./Select/AutocompleteMultiselect";
import Select from "./Select/Select";
import Spinner from "./Spinner";
import SpinnerCustom from "./SpinnerCustom";
import Tooltip from "./Tooltip";
import {
  classNames,
  getDomainFromUrl,
  isValidUrl,
  parseAxiosError,
} from "./utils";
import { isValidJson } from "./Utils/commons";
import { useScrollToFirstError } from "./Utils/formUtils";
import { ShowSnackBar } from "./Utils/supportMessage";
import VisibilityFieldCustom from "./VisibilityFieldCustom";

const envString = `${process.env.NEXT_PUBLIC_LABS_INSTANCE}` || "default";

type OpportunityFormProps = {
  pk?: string;
  initData?: any;
  isOpen?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mutate?:
    | KeyedMutator<PaginatedList<OpportunityDetail>>
    | KeyedMutator<GTMProjectDetailResponse>;
  org?: OrganizationAutocompleteResult | null;
  backUrl?: string;
  callBack?: (opportunity: OpportunityDetail | null) => void;
  defaultOpportunityType?: number | undefined | null;
  isRevive?: boolean;
  newOpportunity?: (opportunity: OpportunityDetail | null) => void;
  additionalFieldsValue?: {
    source: string;
    country: string | null;
    sector: string | null;
  };
};

const OpportunityForm = forwardRef(
  (
    {
      pk,
      initData,
      isOpen,
      setIsOpen,
      mutate,
      org,
      backUrl,
      callBack,
      defaultOpportunityType = null,
      isRevive,
      newOpportunity,
      additionalFieldsValue,
    }: OpportunityFormProps,
    ref,
  ) => {
    const { types: opportunityTypes } = useOpportunity();
    const router = useRouter();
    const { data: selectedGroup } = useSWR<any>(GLOBAL_GROUP_KEY);
    const { user: currentUser } = useCurrentUser();
    const initialOwner = {
      label: currentUser.name,
      value: currentUser.id,
      email: currentUser.email,
      image_url: currentUser.image_url,
    };

    const { data: hostedDomains } = useSWR<Success<string[]>>(
      `/api/people_map/hosted_domains`,
      getter,
    );

    const formikRef = useRef<FormikProps<OpportunityFormValues>>(null);

    const [sortedOpportunityTypes, setSortedOpportunityTypes] = useState<
      OpportunityType[]
    >([]);

    const [additionalComponentsAllTypes, setAdditionalComponentsAllTypes] =
      useState<AdditionalComponents[]>([]);

    const [
      additionalComponentsOfCurrentOppType,
      setAdditionalComponentsOfCurrentOppType,
    ] = useState<AdditionalComponents | null>();

    const [currentOpportunityDetail, setCurrentOpportunityDetail] =
      useState<OpportunityDetail>();

    const [showAddToPortfolio, setShowAddToPortfolio] =
      useState<boolean>(false);

    const [openModalApiError, setOpenModalApiError] = useState<boolean>(false);
    const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [submit, setSubmit] = useState<boolean>(false);
    const [formErrors, setFormErrors] =
      useState<FormikErrors<OpportunityFormValues>>();

    const [currentOpportunityType, setCurrentOpportunityType] =
      useState<OpportunityType>();

    const [sourceOrg, setSourceOrg] = useState<AutocompleteResult | null>(null);

    const submitButtonRef = useRef<HTMLButtonElement>(null);

    useScrollToFirstError(formErrors, submit, setSubmit);

    useImperativeHandle(ref, () => ({
      continueEditing() {
        if (submitButtonRef.current) {
          submitButtonRef.current?.click();
        }
      },
    }));

    useEffect(() => {
      if (currentOpportunityType && opportunityTypes) {
        const opportunityType = getOpportunityType(
          opportunityTypes,
          currentOpportunityType.id,
        );
        setOpportunityTypeInSession(currentOpportunityType.id, {
          value: opportunityType.funnel[0].id,
          label: opportunityType.funnel[0].name,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOpportunityType]);

    useEffect(() => {
      if (pk) {
        getOpportunity(parseInt(pk)).then((response: any) => {
          if (isRevive) {
            response.funnel_stage = response.opportunity_type.funnel[0];
            response.rejected_at = null;
            response.rejected_reason = "";
            response.attachments = [];
          }
          setCurrentOpportunityDetail(response);
          setCurrentOpportunityType(response.opportunity_type);
        });
      } else {
        if (initData) {
          setCurrentOpportunityDetail({ ...initData, owner: initialOwner });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pk, isRevive]);

    let defaultEmptyFormValue = emptyFormValue({
      defaultOpportunityType,
      org,
      selectedGroup,
      initialOwner,
      additionalFieldsValue,
    });

    let storageValues = localStorage.getItem(OPPORTUNITY_FORM);

    const createInitialValues = (
      currentOpportunityDetail: OpportunityDetail | undefined,
    ) => {
      // Editing case
      if (currentOpportunityDetail) {
        return opportunityToFormValues(currentOpportunityDetail);
      } else {
        let initial;
        // Create new with existing session
        if (!pk && storageValues && isValidJson(storageValues)) {
          // If has default organization
          if (org) {
            initial = {
              ...defaultEmptyFormValue,
              ...JSON.parse(storageValues),
              name: org.label,
              description: org.description,
              source_organization: org,
              additional_fields: additionalFieldsValue ?? null,
            };
          } else {
            initial = {
              ...defaultEmptyFormValue,
              ...JSON.parse(storageValues),
              name: "",
              description: "",
              source_organization: null,
              additional_fields: additionalFieldsValue ?? null,
            };
          }
        } else {
          initial = defaultEmptyFormValue;
        }

        if (defaultOpportunityType) {
          initial = { ...initial, opportunity_type: defaultOpportunityType };
        }
        return initial;
      }
    };

    const initialValues = createInitialValues(currentOpportunityDetail);

    useEffect(() => {
      if (opportunityTypes) {
        const oppType = getOpportunityType(
          opportunityTypes,
          currentOpportunityDetail
            ? currentOpportunityDetail.opportunity_type.id
            : (defaultOpportunityType ?? 0),
        );
        setCurrentOpportunityType(oppType);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOpportunityDetail, opportunityTypes]);

    useEffect(() => {
      if (opportunityTypes && opportunityTypes.length > 0) {
        //find all investment opportunity types
        const investmentTypes = opportunityTypes.filter(
          (oppType) => oppType.name === INVESTMENT_OPPORTUNITY_TYPE,
        );

        const baseOpportunityTypes = opportunityTypes.filter(
          (oppType) => oppType.name !== INVESTMENT_OPPORTUNITY_TYPE,
        );

        // Sorting bubble buttons
        setSortedOpportunityTypes([
          ...investmentTypes,
          ...baseOpportunityTypes,
        ]);

        if (investmentTypes.length > 0) {
          const investmentOpportunityType = opportunityTypes.filter(
            (oppType) => oppType.id === getDefaultOppType(opportunityTypes),
          )[0];

          // Set default funnel when creating
          defaultEmptyFormValue.funnel_stage = {
            value: investmentOpportunityType.funnel[0].id,
            label: investmentOpportunityType.funnel[0].name,
          };

          defaultEmptyFormValue.description = org
            ? `${investmentOpportunityType.name} - ${org.label}`
            : "";

          if (investmentOpportunityType.additional_fields) {
            let properties =
              investmentOpportunityType.additional_fields.properties;
            let keys = Object.keys(properties);

            //Move field to the bottom
            if (keys.includes("fund")) {
              properties["fund"].order = keys.length;
            }
            if (keys.includes("priority")) {
              properties["priority"].order = keys.length + 1;
            }
            if (keys.includes("funds")) {
              properties["funds"].order = keys.length;
            }
          }
        }
      }
      // eslint-disable-next-line
    }, [opportunityTypes]);

    //Collect all additional fields of all Opportunity Type
    useEffect(() => {
      if (opportunityTypes) {
        //New List Additional Fields Components for each Opportunity Type
        let additionalComponentsList: AdditionalComponents[] = [];
        //Loop for each Opportunity Type
        opportunityTypes?.forEach((oppType) => {
          if (oppType.additional_fields) {
            //Additional Fields with current opportunity type
            let additionalComponents: AdditionalComponents = {
              opportunityTypeId: oppType.id,
              components: [],
            };

            if (oppType.additional_fields.properties) {
              //Get all additional fields name
              const fieldNames = Object.keys(
                oppType.additional_fields.properties,
              );

              //Build addtional component
              fieldNames.forEach((fieldName: string) => {
                // Ignore if that is sub_sector
                if (fieldName === "sub_sector") {
                  return;
                }
                //Get current addtional field
                const field = oppType.additional_fields.properties[fieldName];
                let fieldType = null;
                if (
                  Array.isArray(field["anyOf"]) &&
                  field["anyOf"].length > 0
                ) {
                  fieldType =
                    field["anyOf"]["0"]["type"] ?? field["anyOf"]["0"]["$ref"];
                }

                //Check should show source detail if dealsource === "others"
                const showSourceDetail = () => {
                  if (initialValues.additional_fields) {
                    const source = initialValues.additional_fields["source"];
                    if (source && source === "Others") {
                      return false;
                    }
                  }
                  return true;
                };

                const title =
                  oppType.additional_fields.properties[fieldName].title;

                // Field Types can be either a primitive type or a reference $ref
                // If primitive, we can add it to the form directly
                // If $ref, we need to get the enum values and create a dropdown
                // Date needs to be checked separately as the format is {"type": "string", "format": "date"}
                if (
                  fieldType &&
                  fieldType !== "array" &&
                  !fieldType.startsWith("#")
                ) {
                  const parsedFieldType =
                    "format" in field["anyOf"]["0"] &&
                    field["anyOf"]["0"]["format"] === "date"
                      ? "date"
                      : fieldType;
                  additionalComponents.components.push({
                    title: title,
                    type: parsedFieldType,
                    fieldName: fieldName,
                    component: {
                      hidden:
                        fieldName === "source_details"
                          ? showSourceDetail()
                          : false,
                    },
                    order: field.order,
                    format: parsedFieldType === "date" ? "date" : undefined,
                  });
                } else {
                  //Collect all Dropdown  and Initial Value
                  let definitionField;
                  if (fieldType && fieldType === "array") {
                    definitionField = field.anyOf["0"].items.$ref.split("/")[2];
                  } else {
                    if (fieldType?.startsWith("#")) {
                      definitionField = fieldType.split("/")[2];
                    }
                  }

                  if (definitionField) {
                    const dropdownField =
                      oppType.additional_fields?.$defs[definitionField];

                    additionalComponents.components.push({
                      title: title,
                      type: "dropdown",
                      component: {
                        definition: definitionField,
                        options: dropdownField.enum,
                      },
                      fieldName: fieldName,
                      order: field.order,
                      format: field?.format,
                    });
                  } else {
                    console.log("Uncaught field", definitionField);
                  }
                }
              });
            }
            //Adding to list
            additionalComponentsList.push(additionalComponents);
          } else {
            let additionalComponents: AdditionalComponents = {
              opportunityTypeId: oppType.id,
              components: [],
            };
            additionalComponentsList.push(additionalComponents);
          }
        });
        setAdditionalComponentsAllTypes(additionalComponentsList);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOpportunityDetail, opportunityTypes]);

    //Get additional fields of current Opportunity Type
    React.useEffect(() => {
      if (additionalComponentsAllTypes.length > 0) {
        let field = additionalComponentsAllTypes.filter(
          (item: AdditionalComponents) =>
            item.opportunityTypeId === currentOpportunityType?.id,
        )[0];
        if (field) {
          field.components = field.components.sort((a, b) => a.order - b.order);
          setAdditionalComponentsOfCurrentOppType(field);
        }
      }
    }, [currentOpportunityType, additionalComponentsAllTypes]);

    const onUpdatedSucceed = (opportunityDetail: OpportunityDetail) => {
      newOpportunity && newOpportunity(opportunityDetail);
      if (mutate) {
        mutate();
      }
      if (!isOpen || isRevive) {
        router.push(backUrl ? backUrl : `/opportunity/${opportunityDetail.id}`);
      } else {
        setIsOpen(false);
      }

      if (callBack) {
        callBack(opportunityDetail);
      }
    };

    const submitForm = async (
      values: OpportunityFormValues,
      formikHelpers: FormikHelpers<OpportunityFormValues>,
    ) => {
      const { setErrors, setStatus } = formikHelpers;
      const formData = new FormData();

      if (values.attachments && values.attachments.length) {
        values.attachments.map((att) => {
          formData.append("attachments", att);
        });
      }

      let payload = {
        name: values.name,
        description: values.description,
        opportunity_type: values.opportunity_type as number,
        owner: values.owner?.value as number,
        owner_groups: values.owner_groups?.map((option) => option.value),
        // need to do a runtime conversion here because the built-in HTML select returns value as string
        funnel_stage: Number(values.funnel_stage?.value),
        visibility: values.visibility,
        groups:
          values.visibility === "groups"
            ? values.groups.map((option) => option.value)
            : [],
        users:
          values.visibility === "groups"
            ? values.users.map((option) => option.value)
            : [],
        rejected_at:
          values.rejected && values.rejected_at
            ? new Date(values.rejected_at.toString())
            : null,
        rejected_reason: values.rejected_reason,
        to_revisit_at:
          values.to_revisit && values.to_revisit_at
            ? new Date(values.to_revisit_at.toString())
            : null,
        to_revisit_reason: values.to_revisit ? values.to_revisit_reason : "",
        additional_fields: values.additional_fields,
        tags: values.tags.map((tag) => tag.label),
        partner: values.partner?.value as number,
        team_members: values.team_members.map((option) => option.value),
        attachment_ids: values.attachment_ids
          ? values.attachment_ids.map((attachment) => attachment.value)
          : [],
        originator: values.originator?.value as number,
        source_organization: {},
        target_organization: {},
        contacts: values.contacts.map((option) => option.value),
      };

      if (values.generate_source_website) {
        payload.source_organization = {
          name: values.source_organization?.value,
          website: values.source_website,
        };
      } else {
        payload.source_organization = values.source_organization?.value as
          | number
          | string;
      }

      if (values.generate_target_website) {
        payload.target_organization = {
          name: values.target_organization?.value,
          website: values.target_website,
        };
      } else {
        payload.target_organization = values.target_organization?.value as
          | number
          | string;
      }

      if (payload.additional_fields?.sector) {
        const newCurrentSector = values.sector_list.filter(
          (item: Sector) => item.sector === payload.additional_fields.sector,
        )[0];
        if (newCurrentSector) {
          payload.additional_fields.sub_sector = newCurrentSector.childs;
        }
      } else {
        delete payload?.additional_fields?.sub_sector;
      }

      let currentAddtionalFields =
        additionalComponentsOfCurrentOppType?.components.map(
          (comp) => comp.fieldName,
        ) || [];

      if (currentAddtionalFields.includes("sector")) {
        currentAddtionalFields = [...currentAddtionalFields, "sub_sector"];
      }

      if (payload?.additional_fields) {
        const addtionalFieldsValue = Object.keys(payload?.additional_fields);

        if (addtionalFieldsValue?.length > 0) {
          addtionalFieldsValue.forEach((fieldName: string) => {
            if (!currentAddtionalFields.includes(fieldName)) {
              delete payload?.additional_fields?.[fieldName];
            }
          });
        }
      }

      formData.append("params", JSON.stringify(payload));
      const submitFunction =
        pk && !isRevive
          ? updateOpportunity(formData as any, pk)
          : createOpportunity(formData as any);
      setIsSubmitting(true);
      return submitFunction
        .then((res) => {
          const opportunityDetail = res.data.data as OpportunityDetail;
          setCurrentOpportunityDetail(opportunityDetail);
          setStatus(opportunityDetail);

          const lastStage =
            opportunityDetail.opportunity_type.funnel[
              opportunityDetail.opportunity_type.funnel.length - 1
            ];
          ShowSnackBar(
            pk && !isRevive
              ? "Opportunity Updated Successfully"
              : "Opportunity Created Successfully",
            true,
          );
          if (lastStage.id === payload.funnel_stage) {
            getOrganization(opportunityDetail.source_organization.id).then(
              (response) => {
                if (response.data) {
                  const organizationDetail = response.data
                    .data as OrganizationDetail;
                  if (
                    organizationDetail.funds.length === 0 &&
                    currentOpportunityType?.id === 2
                  ) {
                    setShowAddToPortfolio(true);
                    document
                      .getElementById("opportunityModal")
                      ?.classList.add("opacity-0");
                  } else {
                    onUpdatedSucceed(opportunityDetail);
                  }
                }
              },
            );
          } else {
            onUpdatedSucceed(opportunityDetail);
          }

          const stringifyOppDetail = JSON.stringify(
            opportunityToFormValues(opportunityDetail),
          );

          updateOpportunityInSession({
            defaultValues: defaultEmptyFormValue,
            data: JSON.parse(stringifyOppDetail),
          });
        })
        .catch((error) => {
          switch (error.response.status) {
            case 422:
              const errors: PydanticError = error.response.data;
              // formik errors from pydantic body errors
              setErrors(
                Object.fromEntries(errors.detail.map((v) => [v.loc[2], v.msg])),
              );
              return;
            case 400:
            case 403:
              setErrors(error.response.data.data);
              setApiErrorMessage(parseAxiosError(error));
              setOpenModalApiError(true);
              return;
            case 500:
              setOpenModalApiError(true);
              setApiErrorMessage(error.message);
              return;
            case 413:
              setOpenModalApiError(true);
              setApiErrorMessage(
                "Error: File size is more than the permitted limit of 32MB.",
              );
              return;
          }
          setStatus(error.response.data);
        })
        .finally(() => setIsSubmitting(false));
    };

    const validate = (values: OpportunityFormValues) => {
      let errors: FormikErrors<OpportunityFormValues> = {};
      if (!values.opportunity_type) {
        errors.opportunity_type = "Opportunity is required";
      }

      if (!values.source_organization) {
        errors.source_organization = "Organization is required";
      }

      if (
        values.source_organization &&
        values.source_organization.label.trim().length < 3
      ) {
        errors.source_organization =
          "Source Organization needs to be at least 3 characters";
      }

      if (
        values.target_organization &&
        values.target_organization.label.trim().length < 3
      ) {
        errors.target_organization =
          "Target Organization needs to be at least 3 characters";
      }

      if (values.opportunity_type) {
        if (
          currentOpportunityType?.has_destination_organization &&
          !values.target_organization
        ) {
          errors.target_organization = "Target Organization is required";
        }
      }

      if (!values.description) {
        errors.description = "Description is required";
      }
      if (!values.owner) {
        errors.owner = "Owner is required";
      }
      if (!values.funnel_stage?.value) {
        errors.funnel_stage = "Funnel stage is required";
      }
      if (
        values.visibility === "groups" &&
        (!values.groups || values.groups?.length === 0) &&
        (!values.users || values.users?.length === 0)
      ) {
        errors.groups = "At least one group OR user should be selected";
      }
      if (
        values.to_revisit &&
        (values.to_revisit_at === null || !values.to_revisit_reason)
      ) {
        errors.to_revisit_reason = "Revisit reason is required";
      }

      if (values.generate_source_website) {
        if (values.source_website) {
          if (!isValidUrl(values.source_website)) {
            errors.source_website = "Invalid website format";
          } else if (hostedDomains?.data) {
            const inputDomain = getDomainFromUrl(values.source_website);
            if (inputDomain && hostedDomains.data.includes(inputDomain)) {
              errors.source_website = `Do not use hosted domains for the company website ${inputDomain}`;
            }
          }
        } else {
          errors.source_website = "Website is required";
        }
      }

      if (values.generate_target_website) {
        if (values.target_website) {
          if (!isValidUrl(values.target_website)) {
            errors.target_website = "Invalid website format";
          } else if (hostedDomains?.data) {
            const inputDomain = getDomainFromUrl(values.target_website);
            if (inputDomain && hostedDomains.data.includes(inputDomain)) {
              errors.target_website = `Do not use hosted domains for the company website ${inputDomain}`;
            }
          }
        } else {
          errors.target_website = "Website is required";
        }
      }

      // Config Additional Fields Validation
      const addtionalFields = Object.keys(
        currentOpportunityType?.additional_fields?.properties || [],
      );
      if (envString === "vvhc") {
        errors = additionalValidation({
          fields: vvhcMandatoryFields,
          addtionalFields: addtionalFields,
          formValues: values,
          errors: errors,
        });
      } else if (envString === "vvseai") {
        errors = additionalValidation({
          fields: vvseaiMandatoryFields,
          addtionalFields: addtionalFields,
          formValues: values,
          errors: errors,
        });
      } else {
        errors = additionalValidation({
          fields: mandatoryFields,
          addtionalFields: addtionalFields,
          formValues: values,
          errors: errors,
        });
      }
      setFormErrors(errors);
      const currentValues = JSON.parse(JSON.stringify(values));
      localStorage.setItem(
        OPPORTUNITY_FORM,
        JSON.stringify({
          ...defaultEmptyFormValue,
          opportunity_type: currentValues.opportunity_type,
          additional_fields: getPreSelectedAddtionalFields(
            currentValues.additional_fields,
          ),
          visibility: "groups",
          groups: selectedGroup ? Array.of(selectedGroup) : [],
          users: [],
        }),
      );

      return errors;
    };

    const BubbleOpportunityTypeButtons = ({
      values,
      setFieldValue,
    }: {
      values: OpportunityFormValues;
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
      ) => void;
    }) => {
      return (
        <RadioGroup
          value={values.opportunity_type}
          onChange={(newOpportunityId: any) => {
            const opportunityType = getOpportunityType(
              opportunityTypes,
              newOpportunityId,
            );
            setCurrentOpportunityType(opportunityType);
            setFieldValue("opportunity_type", newOpportunityId);

            if (
              selectedGroup &&
              selectedGroup.label === "Partnership Group" &&
              opportunityType.name !== INVESTMENT_OPPORTUNITY_TYPE
            ) {
              values.visibility = "public";
            } else {
              values.visibility = "groups";
            }

            setFieldValue("funnel_stage", {
              value: opportunityType.funnel[0].id,
              label: opportunityType.funnel[0].name,
            });

            if (
              pk &&
              currentOpportunityDetail &&
              currentOpportunityDetail.opportunity_type.id === newOpportunityId
            ) {
              setFieldValue("funnel_stage", {
                value: currentOpportunityDetail.funnel_stage.id,
                label: currentOpportunityDetail.funnel_stage.name,
              });
            }

            updatePreDescription({
              opportunityType,
              formValues: values,
              setFieldValue,
            });
          }}
          name="opportunity_type"
          className="flex flex-wrap items-center gap-x-2 gap-y-5 md:gap-x-4"
        >
          {sortedOpportunityTypes.map((opportunity_type: OpportunityType) => (
            <RadioGroup.Option
              value={opportunity_type.id}
              key={opportunity_type.id}
            >
              {({ checked }) => (
                <span
                  className={`flex items-center gap-x-2 ${
                    checked
                      ? "cursor-pointer border border-blue-900 bg-blue-100 pl-2 pr-4 text-blue-900"
                      : "border-1 border border-gray-500 px-4 hover:border hover:bg-gray-200"
                  } cursor-pointer rounded-lg py-2 text-xs font-medium`}
                >
                  {checked && <CheckIcon className="h-4 w-4" />}
                  {opportunity_type.name}
                </span>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      );
    };

    useEffect(() => {
      if (formikRef.current) {
        if (formikRef.current.values.source_organization) {
          getAndSetLinkedDeals(
            formikRef.current.values.source_organization.value,
            formikRef.current.values.target_organization?.value,
            formikRef.current.values,
            formikRef.current.setFieldValue,
          );
        } else {
          updateLinkedDealsFormValues(
            [],
            formikRef.current.values,
            formikRef.current.setFieldValue,
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOpportunityType]);

    if ((pk && !currentOpportunityDetail) || opportunityTypes.length === 0) {
      return <SpinnerCustom />;
    }

    // Get Linked Deals
    const getAndSetLinkedDeals = (
      sourceOrgId: number,
      targetOrgId: number | undefined,
      values: OpportunityFormValues,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
      ) => void,
    ) => {
      if (sourceOrgId) {
        let payload: Record<string, any> = {
          opportunity_type_id: currentOpportunityType?.id,
        };

        if (currentOpportunityType?.has_destination_organization) {
          if (targetOrgId && !isNaN(targetOrgId)) {
            payload = { ...payload, target_organization_id: targetOrgId };
            getLinkedDeals(sourceOrgId, payload).then((response) => {
              if (response.data) {
                updateLinkedDealsFormValues(
                  response.data.items,
                  values,
                  setFieldValue,
                );
              }
            });
          }
        } else {
          if (sourceOrgId && !isNaN(sourceOrgId)) {
            getLinkedDeals(sourceOrgId, payload).then((response) => {
              if (response.data) {
                updateLinkedDealsFormValues(
                  response.data.items,
                  values,
                  setFieldValue,
                );
              }
            });
          }
        }
      } else {
        updateLinkedDealsFormValues([], values, setFieldValue);
      }
    };

    return (
      <div>
        <Formik<OpportunityFormValues>
          innerRef={formikRef}
          initialValues={{
            ...initialValues,
            description:
              initialValues.description ??
              initialValues.source_organization?.description ??
              "",
            oppDescription:
              opportunityTypes.map((oppType) => {
                return {
                  typeId: oppType.id,
                  name: "",
                  description: "",
                  linkedDeals: [],
                } as OpportunityDescription;
              }) ?? [],
          }}
          onSubmit={submitForm}
          validate={validate}
        >
          {({
            errors,
            touched,
            values,
            setFieldValue,
            setFieldTouched,
            resetForm,
          }) => {
            const linkedDeals = getLinkedDealsFromOppType(
              values,
              currentOpportunityType?.id,
              pk ? Number(pk) : undefined,
            );
            return (
              <Form>
                <BubbleOpportunityTypeButtons
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <div className="mt-5 grid w-full grid-cols-1 md:grid-cols-2 md:gap-x-8">
                  <div className="flex flex-col gap-y-6">
                    <div className="flex flex-col gap-y-5">
                      <div className="relative w-full text-2xs md:text-xs">
                        <Field
                          as={FormCompanySelectField}
                          id="opportunity-form-source-organization-field"
                          name="source_organization"
                          label="Organization Name"
                          placeholder={"Select Organization"}
                          errors={errors.source_organization}
                          touched={touched.source_organization}
                          flexible={true}
                          withNinja={true}
                          required
                          directCreate={true}
                          customClass={""}
                          showAddOrganizationButton={true}
                          onChange={(sourceValue: any) => {
                            values.source_organization = sourceValue;
                            values.generate_source_website = sourceValue?.isNew;
                            values.source_website = sourceValue?.isNew
                              ? "https://"
                              : "";
                            updateNameAndDescriptionValues({
                              opportunityType: currentOpportunityType,
                              formValues: values,
                              setFieldValue,
                            });
                            setSourceOrg(sourceValue);

                            getAndSetLinkedDeals(
                              sourceValue?.value,
                              values.target_organization?.value,
                              values,
                              setFieldValue,
                            );
                          }}
                          onBlur={() => {
                            setFieldTouched("source_organization", true);
                          }}
                        />
                        {showLinkedDeals(values, currentOpportunityType) && (
                          <div className="mt-2 flex flex-1 flex-wrap items-center justify-start gap-x-2 gap-y-1">
                            {linkedDeals.map((deal: OpportunityDetail) => (
                              <OpportunityLinkedDeal
                                opportunity={deal}
                                key={deal.id}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                      {values.generate_source_website && (
                        <div className="relative md:w-full">
                          <div className="absolute right-0 flex text-xs md:right-8 lg:right-16">
                            <input
                              onChange={(event) => {
                                if (event.target.checked) {
                                  if (values.source_organization) {
                                    setFieldValue(
                                      "source_website",
                                      "https://unknown-" +
                                        generateDomainFromCompanyName(
                                          values.source_organization.label,
                                        ) +
                                        ".com",
                                    );
                                  }
                                } else {
                                  setFieldValue("source_website", "https://");
                                }
                                touched.source_website = true;
                              }}
                              type="checkbox"
                              value="generate_source_website"
                              className="h-4 w-4 cursor-pointer rounded-full border-gray-500 text-blue-600 focus:ring-white"
                            />
                            <div className="ml-2 text-2xs">Website unknown</div>
                          </div>
                          <Field>
                            {({
                              form: { setFieldValue, errors, touched },
                            }: FieldProps) => {
                              return (
                                <FormTextField
                                  customClass="md:w-full"
                                  name="source_website"
                                  label="Website"
                                  placeholder="Source Website"
                                  errors={
                                    errors.source_website
                                      ? String(errors.source_website)
                                      : undefined
                                  }
                                  touched={Boolean(touched.source_website)}
                                  value={
                                    values.source_website &&
                                    values.source_website.trim().length > 0
                                      ? values.source_website
                                      : ""
                                  }
                                  onChange={(event: any) => {
                                    let value = event.target.value;
                                    if (
                                      values.source_website.trim().length ===
                                        0 &&
                                      !value.includes("https://")
                                    ) {
                                      value = `https://${event.target.value}`;
                                    }
                                    setFieldValue("source_website", value);
                                    touched.source_website = true;
                                  }}
                                  required={true}
                                  onBlur={() => {
                                    setFieldTouched("source_website", true);
                                  }}
                                />
                              );
                            }}
                          </Field>
                        </div>
                      )}
                      {currentOpportunityType?.has_destination_organization && (
                        <div className="relative text-xs">
                          <Field
                            as={FormCompanySelectField}
                            id="opportunity-form-target-organization-field"
                            name="target_organization"
                            label="And"
                            placeholder={"Search Company"}
                            errors={errors.target_organization}
                            touched={touched.target_organization}
                            flexible={true}
                            required
                            withNinja={true}
                            directCreate={true}
                            showAddOrganizationButton={true}
                            customClass={""}
                            onChange={(targetValue: any) => {
                              values.target_organization = targetValue;
                              values.generate_target_website =
                                targetValue?.isNew;
                              values.target_website = targetValue?.isNew
                                ? "https://"
                                : "";

                              if (sourceOrg && targetValue) {
                                getAndSetLinkedDeals(
                                  sourceOrg?.value,
                                  targetValue.value,
                                  values,
                                  setFieldValue,
                                );
                              }

                              updateNameAndDescriptionValues({
                                opportunityType: currentOpportunityType,
                                formValues: values,
                                setFieldValue,
                              });

                              delete errors.target_organization;
                            }}
                            onBlur={() => {
                              setFieldTouched("target_organization", true);
                            }}
                          />
                        </div>
                      )}
                      {currentOpportunityType?.has_destination_organization &&
                        values.generate_target_website && (
                          <div className="relative md:w-full">
                            <div className="absolute right-0 flex text-2xs md:right-8 md:text-xs lg:right-10">
                              <input
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    setFieldValue(
                                      "target_website",
                                      "https://unknown-" +
                                        generateDomainFromCompanyName(
                                          values.target_organization.label,
                                        ) +
                                        ".com",
                                    );
                                  } else {
                                    setFieldValue("target_website", "https://");
                                  }
                                  touched.target_website = true;
                                }}
                                type="checkbox"
                                value="generate_target_website"
                                className="h-4 w-4 cursor-pointer rounded-full border-gray-500 text-blue-600 ring-1 ring-white"
                              />
                              <span className="ml-2 text-2xs">
                                Website unknown
                              </span>
                            </div>
                            <Field>
                              {({
                                form: { setFieldValue, errors, touched },
                              }: FieldProps) => {
                                return (
                                  <FormTextField
                                    customClass="md:w-full"
                                    name="target_website"
                                    label="Website"
                                    placeholder="Target Website"
                                    errors={
                                      errors.target_website
                                        ? String(errors.target_website)
                                        : undefined
                                    }
                                    touched={Boolean(touched.target_website)}
                                    value={
                                      values.target_website &&
                                      values.target_website.trim().length > 0
                                        ? values.target_website
                                        : ""
                                    }
                                    onChange={(event: any) => {
                                      let value = event.target.value;
                                      if (
                                        values.target_website.trim().length ===
                                          0 &&
                                        !value.includes("https://")
                                      ) {
                                        value = `https://${event.target.value}`;
                                      }
                                      setFieldValue("target_website", value);
                                      touched.target_website = true;
                                    }}
                                    required={true}
                                    onBlur={() => {
                                      setFieldTouched("target_website", true);
                                    }}
                                  />
                                );
                              }}
                            </Field>
                          </div>
                        )}
                    </div>

                    <div className="relative text-xs">
                      <Field>
                        {({
                          form: { values, touched, errors },
                        }: FieldProps) => {
                          if (values.owner) {
                            touched.owner = true;
                          }
                          return (
                            <FormUserSelectField
                              id="opportunity-form-owner-field"
                              name="owner"
                              label="Owner"
                              customClass={""}
                              errors={errors.owner ? String(errors.owner) : ""}
                              touched={Boolean(touched.owner)}
                              required
                              isClearable={true}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="relative md:w-full">
                      <Field>
                        {({
                          form: { values, setFieldValue, touched, errors },
                        }: FieldProps) => {
                          if (values.name) {
                            delete errors.name;
                            if (values.name.trim().length < 3) {
                              errors.name =
                                "Name needs to be at least 3 characters";
                            }
                            if (values.name.trim().length > 100) {
                              errors.name =
                                "Name should have at most 100 characters";
                            }
                          } else {
                            errors.name = "Name is required";
                          }
                          return (
                            <FormTextField
                              name="name"
                              label="Opportunity Name"
                              customClass="sm:!w-full"
                              errors={
                                errors.name ? String(errors.name) : undefined
                              }
                              touched={Boolean(touched.name)}
                              required
                              isClearable={true}
                              value={values.name}
                              onChange={(event: any) => {
                                setFieldValue("nameChanged", true);
                                setFieldValue("name", event.target.value);
                                updateFieldValue({
                                  opportunityType: currentOpportunityType,
                                  formValues: values,
                                  fieldName: "name",
                                  newValue: event.target.value,
                                  setFieldValue,
                                });
                              }}
                              onBlur={() => {
                                setFieldTouched("name", true);
                              }}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="relative">
                      <Field>
                        {({
                          form: { values, setFieldValue, touched, errors },
                        }: FieldProps) => {
                          if (values.description) {
                            delete errors.description;
                          } else {
                            errors.description = "Description is required";
                          }
                          return (
                            <FormTextAreaField
                              name="description"
                              label="Description"
                              errors={
                                errors.description
                                  ? String(errors.description)
                                  : undefined
                              }
                              touched={Boolean(touched.description)}
                              required
                              rows={4}
                              isClearable={true}
                              value={values.description}
                              onChange={(event: any) => {
                                setFieldValue(
                                  "description",
                                  event.target.value,
                                );
                                setFieldValue("descriptionChanged", true);
                                updateFieldValue({
                                  opportunityType: currentOpportunityType,
                                  formValues: values,
                                  fieldName: "description",
                                  newValue: event.target.value,
                                  setFieldValue,
                                });
                              }}
                              onBlur={() => {
                                setFieldTouched("description", true);
                              }}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    {values.opportunity_type && (
                      <div className="grid flex-col">
                        <div className="flex">
                          <label
                            className="text-xs font-semibold"
                            htmlFor="dateField"
                          >
                            Funnel Stage
                          </label>
                          <div className="text-sm font-semibold text-red-500">
                            *
                          </div>
                        </div>
                        <div
                          className={`relative h-fit w-full items-center rounded-md ${
                            touched.funnel_stage && errors.funnel_stage
                              ? "border-1 border border-red-500"
                              : "border-1 border border-blue-900"
                          }`}
                        >
                          <Field name="funnel_stage">
                            {({
                              field,
                              form: { values, setFieldValue, touched, errors },
                            }: FieldProps) => {
                              if (values.funnel_stage) {
                                touched.funnel_stage = true;
                              }
                              return (
                                <div className="mr-7 w-full text-xs">
                                  <Select
                                    id="opportunity-form-funnel-stage-field"
                                    value={values.funnel_stage}
                                    options={(
                                      currentOpportunityType?.funnel || []
                                    ).map((fn) => {
                                      return {
                                        value: fn.id,
                                        label: fn.name,
                                      };
                                    })}
                                    onChange={(newValue: any) => {
                                      setFieldValue(field.name, newValue)!;
                                      touched.funnel_stage = true;
                                    }}
                                    isClearable
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </div>
                        <div
                          className={classNames(
                            touched && errors
                              ? "text-2xs text-red-500"
                              : "hidden",
                          )}
                        >
                          {errors.funnel_stage as any}
                        </div>
                      </div>
                    )}
                    <div className="h-fit">
                      {pk ? (
                        <VisibilityFieldCustom values={values.visibility} />
                      ) : (
                        <VisibilityFieldForOpportunity
                          values={values.visibility}
                        />
                      )}
                    </div>
                    <div>
                      <div className="mb-2 flex items-center">
                        <label className="text-xs font-semibold">Tags</label>
                        <Tooltip
                          title="What are tags?"
                          content="Tags are free-form entries for easy categorisation of your notes. Tags will be seen for all users if your privacy settings are set to all."
                        />
                      </div>
                      <div
                        className={`border-1 h-10.5 relative block rounded-md text-xs`}
                      >
                        <Field name="tags" className="">
                          {({
                            field: { name, value },
                            form: { setFieldValue, touched, errors },
                          }: FieldProps) => (
                            <>
                              <AutocompleteMultiselect
                                id="tags"
                                placeholder="Select Tags"
                                autocompleteEndpoint="/api/people_map/autocomplete/meeting_note_tags"
                                selected={value}
                                onChange={(newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                creatable={true}
                                isClearable
                              />
                              <div
                                className={classNames(
                                  touched && errors
                                    ? "absolute ml-2 text-xs"
                                    : "hidden",
                                )}
                              >
                                {errors.tags as any}
                              </div>
                            </>
                          )}
                        </Field>
                        <FieldError name="tags" />
                      </div>
                    </div>
                    <div>
                      <div className="flex-col text-xs">
                        <div className="flex items-center">
                          <span className="mr-3 inline-flex font-semibold text-red-500">
                            Closed/Rejected
                          </span>
                          <Field name="rejected">
                            {({
                              field,
                              form: { setFieldValue },
                            }: FieldProps) => (
                              <input
                                type="checkbox"
                                id="rejected"
                                checked={field.value}
                                className="h-5 w-5 cursor-pointer rounded-full text-xs text-red-500 focus:ring-transparent"
                                {...field}
                                value={field.value ?? ""}
                                onChange={(e) => {
                                  setFieldValue(
                                    field.name,
                                    e.currentTarget.checked,
                                  );
                                  setFieldValue(
                                    "rejected_at",
                                    e.currentTarget.checked
                                      ? format(new Date(), "yyyy-MM-dd")
                                      : null,
                                  );
                                  setFieldValue("rejected_reason", "");
                                }}
                              />
                            )}
                          </Field>
                        </div>
                        {values.rejected && (
                          <div
                            key={values.opportunity_type}
                            className="mt-2 flex flex-col gap-y-2"
                          >
                            <Field name="rejected_at">
                              {({ field }: FieldProps) => (
                                <input
                                  id="rejected_at"
                                  type="date"
                                  className="rounded-md border-gray-300 text-xs"
                                  {...field}
                                />
                              )}
                            </Field>
                            {additionalComponentsOfCurrentOppType?.components.map(
                              (component: AdditionalComponent) => {
                                if (
                                  [
                                    "inactive_reason",
                                    "rejection_reason",
                                  ].includes(component.fieldName)
                                ) {
                                  return (
                                    <Field key={component.fieldName}>
                                      {({ form }: FieldProps) => (
                                        <AdditionalDropdown
                                          component={component}
                                          form={form}
                                          envString={envString}
                                          additionComponents={
                                            additionalComponentsOfCurrentOppType
                                          }
                                          hideTitle={true}
                                        />
                                      )}
                                    </Field>
                                  );
                                }
                                return (
                                  <div
                                    key={component.fieldName}
                                    className="hidden"
                                  ></div>
                                );
                              },
                            )}
                            <div className="border-1 rounded-md border-gray-300 text-xs">
                              <FormTextAreaField
                                name="rejected_reason"
                                label=""
                                placeHolder="Reason"
                                errors={
                                  errors.rejected_reason
                                    ? String(errors.rejected_reason)
                                    : undefined
                                }
                                touched={Boolean(touched.rejected_reason)}
                                required={false}
                                isClearable={true}
                                value={values.rejected_reason}
                                onChange={(event: any) => {
                                  setFieldValue(
                                    "rejected_reason",
                                    event.target.value,
                                  );
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {envString !== "vvhc" && (
                      <div>
                        <div className="flex-col text-xs">
                          <div className="flex items-center">
                            <span className="inline-flex font-semibold text-red-500">
                              Revisit
                            </span>
                            <Field name="to_revisit">
                              {({ field }: FieldProps) => (
                                <input
                                  type="checkbox"
                                  id="to_revisit"
                                  checked={field.value}
                                  className="ml-3 h-5 w-5 cursor-pointer rounded-full text-xs text-red-500 focus:ring-transparent"
                                  {...field}
                                  onChange={(e) => {
                                    setFieldValue(
                                      field.name,
                                      e.currentTarget.checked,
                                    );
                                    setFieldValue(
                                      "to_revisit_at",
                                      format(
                                        addMonths(new Date(), 3),
                                        "yyyy-MM-dd",
                                      ),
                                    );
                                    setFieldValue("to_revisit_reason", "");
                                  }}
                                />
                              )}
                            </Field>
                          </div>
                          {values.to_revisit && (
                            <div className="mt-2">
                              <div className="w-full">
                                <Field name="to_revisit_at">
                                  {({ field }: FieldProps) => (
                                    <input
                                      id="to_revisit_at"
                                      type="date"
                                      className="rounded-md border-gray-300 text-xs"
                                      {...field}
                                    />
                                  )}
                                </Field>
                                <FieldError name="date" />
                              </div>
                              <div className="mt-2 flex flex-col">
                                <div className="flex">
                                  <span className="inline-flex font-semibold text-red-500">
                                    Revisit reason
                                  </span>
                                  <div className="text-sm font-semibold text-red-500">
                                    *
                                  </div>
                                </div>
                                <Field
                                  name="to_revisit_reason"
                                  type="text"
                                  id="to_revisit_reason"
                                  placeholder="Reason"
                                  className={`rounded-md text-xs ${touched.to_revisit_reason ? (values.to_revisit_reason ? "border-blue-900" : "border-red-500") : "border-gray-300"}`}
                                />
                                <div className="flex">
                                  <FieldError name="to_revisit_reason" />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mt-0.5 flex flex-col gap-y-6">
                    <div className="relative text-xs">
                      <Field>
                        {({
                          form: { values, touched, errors },
                        }: FieldProps) => {
                          if (values.partner) {
                            touched.partner = true;
                          }
                          return (
                            <FormUserSelectField
                              name="partner"
                              label="Partner"
                              placeholder="Select Partner"
                              customClass={""}
                              errors={
                                errors.partner ? String(errors.partner) : ""
                              }
                              touched={Boolean(touched.partner)}
                              isClearable={true}
                              required={false}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="relative text-xs">
                      <Field>
                        {({
                          form: { values, touched, errors },
                        }: FieldProps) => {
                          if (values.team_members?.length) {
                            touched.team_members = true;
                          }
                          return (
                            <FormUserSelectField
                              name="team_members"
                              label="Team"
                              placeholder="Select Team"
                              customClass={""}
                              errors={
                                errors.team_members
                                  ? String(errors.team_members)
                                  : ""
                              }
                              touched={Boolean(touched.team_members)}
                              isClearable={true}
                              required={false}
                              isMulti={true}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="relative text-xs">
                      <Field name="contacts">
                        {({
                          field: { name, value },
                          form: { setFieldValue, touched },
                        }: FieldProps) => {
                          if (values.contacts?.length > 0) {
                            touched.contacts = true;
                          }

                          return (
                            <FormPersonSelectField
                              name="contacts"
                              label="Contacts"
                              placeholder="Select Contacts"
                              errors={
                                errors.contacts ? String(errors.contacts) : ""
                              }
                              touched={Boolean(touched.contacts)}
                              isClearable={true}
                              required={false}
                              isMulti={true}
                              fixWidth={false}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="relative text-xs">
                      <Field>
                        {({
                          form: { values, touched, errors },
                        }: FieldProps) => {
                          if (values.originator) {
                            touched.originator = true;
                          }
                          return (
                            <FormUserSelectField
                              name="originator"
                              label="Originator"
                              customClass={""}
                              errors={
                                errors.originator
                                  ? String(errors.originator)
                                  : ""
                              }
                              touched={Boolean(touched.originator)}
                              required={false}
                              isClearable={true}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    {/* Building Addtional Fields */}
                    {additionalComponentsOfCurrentOppType?.components.map(
                      (component: AdditionalComponent) => {
                        const ignoreDropdownList = [
                          "inactive_reason",
                          "rejection_reason",
                        ];
                        return (
                          <Field key={component.fieldName}>
                            {({ form }: FieldProps) => {
                              if (component.type === "dropdown") {
                                if (radioFields.includes(component.fieldName)) {
                                  return (
                                    <AdditionalRadioGroup
                                      component={component}
                                      form={form}
                                      envString={envString}
                                    />
                                  );
                                }
                                if (
                                  !ignoreDropdownList.includes(
                                    component.fieldName,
                                  )
                                ) {
                                  return (
                                    <>
                                      <AdditionalDropdown
                                        component={component}
                                        form={form}
                                        envString={envString}
                                        additionComponents={
                                          additionalComponentsOfCurrentOppType
                                        }
                                      />

                                      {/* If has sector => show subsector */}
                                      {component.fieldName === "sector" && (
                                        <AdditionalSubSector
                                          component={component}
                                          form={form}
                                        />
                                      )}
                                    </>
                                  );
                                } else return <></>;
                              } else {
                                if (
                                  ["status_note"].includes(component.fieldName)
                                ) {
                                  return (
                                    <AdditionalTextArea
                                      component={component}
                                      form={form}
                                    />
                                  );
                                }
                                return (
                                  <AdditionalTextField
                                    component={component}
                                    form={form}
                                  />
                                );
                              }
                            }}
                          </Field>
                        );
                      },
                    )}
                    <div className="flex flex-col gap-y-2 pr-[8%]">
                      <div className="mt-2 block flex space-x-3">
                        <div className="block text-sm font-semibold">
                          Attachments
                        </div>
                        <PaperClipIcon className="h-4 w-4" />
                      </div>
                      <Field name="attachments">
                        {({
                          field: { name, value },
                          form: { setFieldValue },
                        }: FieldProps<File[]>) => (
                          <div>
                            <div>
                              <Field name="attachment_ids">
                                {({
                                  field: {
                                    name: fieldName,
                                    value: fieldValues,
                                  },
                                  form: {
                                    setFieldValue: setAttachmentFieldValues,
                                  },
                                }: FieldProps<AutocompleteResult[]>) => (
                                  <div className="grid-col-auto grid space-y-1">
                                    {fieldValues &&
                                      fieldValues.map((file) => (
                                        <FileThumbnail
                                          file={file.label}
                                          field={fieldName}
                                          value={fieldValues}
                                          setValue={setAttachmentFieldValues}
                                          key={file.value}
                                        />
                                      ))}
                                  </div>
                                )}
                              </Field>
                              {/* Attachment Files List */}
                              <div className="mb-4 flex flex-col gap-y-2">
                                {value &&
                                  value.map((file) => (
                                    <FileThumbnail
                                      file={file}
                                      field={name}
                                      value={value}
                                      setValue={setFieldValue}
                                      key={file.name}
                                    />
                                  ))}
                              </div>
                            </div>
                            <Dropzone
                              onDrop={(acceptedFiles) => {
                                // do nothing if no files
                                if (acceptedFiles.length === 0) {
                                  return;
                                }
                                // on drop we add to the existing files
                                if (value) {
                                  setFieldValue(
                                    name,
                                    value.concat(acceptedFiles),
                                  );
                                } else {
                                  setFieldValue(name, acceptedFiles);
                                }
                              }}
                            >
                              {({
                                isDragActive,
                                isDragReject,
                                getRootProps,
                                getInputProps,
                              }) => {
                                if (isDragActive) {
                                  return (
                                    <div
                                      className="border-grey-500 flex cursor-pointer flex-col items-center justify-evenly rounded-md border-2 border-dashed border-sky-200 bg-blue-50 p-4"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="text-center">
                                        <h3 className="text-xs font-semibold text-blue-900">
                                          Drop in this file!
                                        </h3>
                                      </div>
                                    </div>
                                  );
                                }

                                if (isDragReject) {
                                  return (
                                    <div
                                      className="border-grey-500 flex cursor-pointer flex-col items-center justify-evenly rounded-md border-2 border-dashed border-sky-200 bg-blue-50 p-4"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <XCircleIcon className="h-10 w-10 fill-red-500" />
                                      <div className="text-center">
                                        <h3 className="text-xs font-semibold text-blue-900">
                                          This file cannot be uploaded!
                                        </h3>
                                      </div>
                                    </div>
                                  );
                                }
                                return (
                                  <div
                                    className="border-grey-500 flex cursor-pointer flex-col items-center justify-evenly rounded-md border-2 border-dashed p-4"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="text-center">
                                      <div className="text-xs text-gray-500">
                                        Drop files here or{" "}
                                        <span className="text-blue-900 underline">
                                          browse
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }}
                            </Dropzone>
                          </div>
                        )}
                      </Field>
                      <FieldError name="attachments" />
                    </div>
                    <div className="mt-10 mb-20 flex flex-row items-center justify-end space-x-4">
                      <button
                        type="button"
                        className={`text-xs text-blue-900`}
                        onClick={() => {
                          let resetData: any = {};
                          if (!currentOpportunityDetail && !pk) {
                            resetData = initialValues;
                          } else {
                            const defaultOppType = getOpportunityType(
                              opportunityTypes,
                              2,
                            );
                            resetData = {
                              ...defaultEmptyFormValue,
                              opportunity_type: defaultOppType.id,
                              funnel_stage: {
                                value: defaultOppType.funnel[0].id,
                                label: defaultOppType.funnel[0].name,
                              },
                            };
                          }
                          resetData.oppDescription =
                            opportunityTypes.map((oppType) => {
                              return {
                                typeId: oppType.id,
                                name: "",
                                description: "",
                                linkedDeals: [],
                              } as OpportunityDescription;
                            }) ?? [];

                          localStorage.setItem(
                            OPPORTUNITY_FORM,
                            JSON.stringify(resetData),
                          );
                          resetForm({ values: resetData });
                        }}
                      >
                        Clear Form
                      </button>
                      <button
                        className="btn-primary"
                        onClick={() => setSubmit(true)}
                      >
                        {isSubmitting ? (
                          <div className="flex flex-row items-center gap-x-2 text-white">
                            <div>
                              <Spinner className="text-blue h-4 w-4" />
                            </div>
                            <div>Submitting</div>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                    <NewModal
                      title="Add New Portfolio"
                      open={showAddToPortfolio}
                      expanded={false}
                      onClose={() => {
                        setShowAddToPortfolio(false);
                        setIsOpen(false);
                        if (mutate) {
                          mutate();
                        }
                      }}
                    >
                      <PortfolioForm
                        defaultOrganization={
                          currentOpportunityDetail?.source_organization
                        }
                        setIsOpen={setShowAddToPortfolio}
                        success={(portfolio: PortfolioOrganizationItem) => {
                          setShowAddToPortfolio(false);
                          if (currentOpportunityDetail) {
                            onUpdatedSucceed(currentOpportunityDetail);
                          }
                        }}
                      />
                    </NewModal>
                    <ErrorModal
                      open={openModalApiError}
                      setOpen={setOpenModalApiError}
                      errorMessage={apiErrorMessage}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  },
);

OpportunityForm.displayName = "OpportunityForm";
export default OpportunityForm;
