import { AxiosError } from "axios";
import { NextRouter } from "next/router";
import {
  getOrganization,
  getOrganizationDetails,
  instance,
  updateVPC,
} from "../../api";
import {
  InvestmentPartners,
  OrganizationDetail,
  OrganizationInvestmentDetail,
  Success,
  VertexPlatform,
  VPCOrganizationParams,
} from "../../api/types";

export const VPCViews = {
  PORTFOLIO_LIST: "Portfolio List",
  CORPORATES: "Corporates",
  LINKED_CORPORATES: "Linked Corporates",
};

//Building Header Tab
type HeaderTabProps = {
  name: string;
  tab: string;
  router: NextRouter;
  view: string;
};

export const HeaderTab = ({ router, view, name, tab }: HeaderTabProps) => {
  return (
    <div
      className="group"
      onClick={() => router.push(`${router.pathname}?tab=${tab}`)}
    >
      <div
        className={`cursor-pointer", gap-x-10 whitespace-nowrap px-1 pb-0 pt-3 text-xs font-medium focus:outline-none focus:ring-0 ${
          view === tab
            ? "text-blue-900"
            : "text-gray-500 group-hover:text-gray-700"
        }`}
      >
        <div className="cursor-pointer pb-2">{name}</div>
        <div
          className={`h-1 w-full rounded-t-md ${
            view === tab ? "bg-blue-900" : "group-hover:bg-gray-300"
          }`}
        ></div>
      </div>
    </div>
  );
};

//Update selected row data after edited
export const updateSelectedRowData = (
  agGrig: any,
  organization: OrganizationDetail,
) => {
  let selectedData = agGrig.api.getSelectedRows()[0] as VertexPlatform;
  if (selectedData) {
    getOrganizationDetails(organization.id).then(
      (response: Success<OrganizationDetail>) => {
        const partners =
          response.data?.vertex_portfolio_company?.investment_details
            ?.flatMap(
              (details: OrganizationInvestmentDetail) =>
                details.investment_partners,
            )
            .filter(
              (
                partner: InvestmentPartners,
                index: number,
                self: InvestmentPartners[],
              ) => index === self.findIndex((p) => p.id === partner.id),
            );

        const earliestFinancingStage = getEarliestFinancingStage(
          response.data.vertex_portfolio_company?.investment_details,
        );
        if (earliestFinancingStage) {
          selectedData.financing_stage = earliestFinancingStage;
        }
        const holdingStatus = aggregateHoldingStatus(
          response.data.vertex_portfolio_company?.investment_details,
        );
        if (holdingStatus) {
          selectedData.holding_status = holdingStatus;
        }
        const investmentDate = getEarliestInvestmentDate(
          response.data.vertex_portfolio_company?.investment_details,
        );
        if (investmentDate) {
          selectedData.investment_date = investmentDate;
        }
        selectedData.investors = organization.investors;
        selectedData.is_public =
          organization.vertex_portfolio_company?.is_public;
        selectedData.organization = organization;
        selectedData.sharepoint_url =
          organization.vertex_portfolio_company?.sharepoint_url;
        selectedData.description =
          organization.vertex_portfolio_company?.description;
        selectedData.investment_details =
          response.data.vertex_portfolio_company?.investment_details;
        selectedData.investment_partners = partners;

        agGrig.api.applyTransaction({ update: [selectedData] });
        agGrig.api.redrawRows();
      },
    );
  }
};

//Update VPC
export const updateVPCOrganizationInCell = async (
  vpc: VertexPlatform,
  onError: any,
) => {
  getOrganization(vpc.organization.id).then(async (response) => {
    if (response.data.data) {
      const organization = response.data.data as OrganizationDetail;

      const formData = new FormData();

      let payload = {
        country_id: vpc.organization.headquarters?.value,
        ticker: organization.ticker,
        name: organization.name,
        technology_id: vpc.organization.technologies
          .map((option) => option.value)
          .join(","),
        tags: vpc.organization.tags.map((tag) => tag.name),
        website: organization.domain,
        industry_id: (organization.industries || [])
          .map((option) => option.value)
          .join(","),
        description: vpc.organization.description,
        linkedin: organization.linkedin,
      };

      formData.append("organization", JSON.stringify(payload));
      const submission_url = `/api/people_map/organizations/${organization.id}`;

      await instance
        .post(submission_url, formData)
        .catch((error: AxiosError) => onError(error));

      let VPCPayload: VPCOrganizationParams = {
        vpc: {
          org_pk: organization.id,
          is_public: organization.vertex_portfolio_company.is_public,
          sharepoint_url: organization.vertex_portfolio_company.sharepoint_url,
          description: vpc.description,
        },
        investments: [],
      };

      organization.vertex_portfolio_company.investment_details.forEach(
        (investor: OrganizationInvestmentDetail) => {
          VPCPayload.investments.push({
            investor_fund_id: investor.investor_fund.id,
            investee_organization_id: investor.investee_organization.id,
            financing_stage_id: investor.financing_stage.id,
            holding_status: investor.holding_status,
            investment_date: investor.investment_date,
            investment_partners: investor.investment_partners.map(
              (partner) => partner.id,
            ),
          });
        },
      );

      updateVPC(organization.vertex_portfolio_company.id, VPCPayload).finally(
        () => {
          document.dispatchEvent(
            new CustomEvent("CELL_UPDATING", {
              detail: {
                cellUpdating: false,
                id: vpc.id,
              },
            }),
          );
        },
      );
    }
  });
};

//Get datasource
export const getClientDataSource = (params: any) => {
  return params.api.context.contextParams.providedBeanInstances.gridOptions
    .rowData;
};

export function deduplicateNames(names: string[]) {
  const nameSet = new Set(names);
  return Array.from(nameSet).sort((a: string, b: string) => {
    return a.localeCompare(b);
  });
}

export function holdingStatusColor(status: string) {
  switch (status) {
    case "exit":
      return "text-red-500";
    case "active":
      return "text-green-500";
    case "partial_exit":
      return "text-yellow-500";
    default:
      return "";
  }
}

export function parseHoldingStatus(status: string) {
  switch (status) {
    case "exit":
      return "Exited";
    case "active":
      return "Active";
    default:
      return "";
  }
}

export function getEarliestFinancingStage(
  investmentDetails: OrganizationInvestmentDetail[],
) {
  const sortedInvestments = investmentDetails?.sort(
    (a: OrganizationInvestmentDetail, b: OrganizationInvestmentDetail) => {
      return (
        new Date(a.investment_date).getTime() -
        new Date(b.investment_date).getTime()
      );
    },
  );
  return sortedInvestments?.length > 0
    ? sortedInvestments[0].financing_stage
    : null;
}

export function getEarliestInvestmentDate(
  investmentDetails: OrganizationInvestmentDetail[],
) {
  const sortedInvestments = investmentDetails?.sort(
    (a: OrganizationInvestmentDetail, b: OrganizationInvestmentDetail) => {
      return (
        new Date(a.investment_date).getTime() -
        new Date(b.investment_date).getTime()
      );
    },
  );
  return sortedInvestments?.length > 0
    ? sortedInvestments[0].investment_date
    : null;
}

export function aggregateHoldingStatus(
  investmentDetails: OrganizationInvestmentDetail[],
) {
  const holdingStatuses = investmentDetails?.map(
    (investment: OrganizationInvestmentDetail) => investment.holding_status,
  );
  const holdingStatusSet = new Set(holdingStatuses);
  if (holdingStatusSet.has("active")) {
    return "active";
  } else if (holdingStatusSet.has("partial_exit")) {
    return "partial_exit";
  } else if (holdingStatusSet.has("exit")) {
    return "exit";
  }
  return null;
}

export const DescriptionTooltipText = () => {
  return (
    <div className="rounded-md bg-gray-800 p-2 text-xs leading-4 text-white">
      <p>Naming conventions for VPCs</p>
      <ul className="list-disc px-4">
        <li>(formerly known as) e.g., Vividly (formerly known as Cresicor)</li>
        <li>
          (also known as) e.g., The Parentinc (also known as Tickled Media)
        </li>
        <li>(acquired by) e..g, Own (acquired by Salesforce)</li>
      </ul>
    </div>
  );
};

export const TagTooltipText = () => {
  return (
    <div className="rounded-md bg-gray-800 p-2 text-xs leading-4 text-white">
      <p>Add "No BD" for the following cases:</p>
      <ul className="list-decimal px-4">
        <li>Exit</li>
        <li>
          Network fund report indicates fair value complete write-off or mark
          down to zero
        </li>
        <li>Negative media publicity that are detrimental for BD</li>
      </ul>
    </div>
  );
};
