import { RadioGroup } from "@headlessui/react";
import { FormikProps } from "formik";
import { isArray } from "lodash";
import { InvestmentSectorDict } from "../../api/constants";
import { Option } from "../../api/types";
import Select from "../Select/Select";
import { classNames } from "../utils";
import {
  AdditionalComponent,
  AdditionalComponents,
  Dropdown,
  OpportunityFormValues,
  TextField,
  inputType,
  isRequiredAddtionalField,
} from "./OpportunityFormUtils";

export const AdditionalTextField = ({
  component,
  form,
}: {
  component: AdditionalComponent;
  form: FormikProps<OpportunityFormValues>;
}) => {
  let inputValue: string = "";
  if (form.values.additional_fields) {
    inputValue = form.values.additional_fields[component.fieldName];
  }

  const inputField = component.component as TextField;
  return !inputField.hidden ? (
    <div key={component.fieldName} className="grid gap-y-2">
      <label key={`label_${component.fieldName}`} className="flex flex-col">
        <div className="text-xs font-semibold">{component.title}</div>
        <div className="relative">
          <input
            key={`input_${component.fieldName}`}
            type={inputType(component) !== "date" ? "text" : "date"}
            value={
              component.type === "number"
                ? !inputValue
                  ? ""
                  : Intl.NumberFormat("en-US").format(Number(inputValue))
                : inputValue
            }
            onChange={(e) => {
              if (component.type === "number") {
                const last = e.currentTarget.value.charAt(
                  e.currentTarget.value.length - 1,
                );
                if (!isNaN(Number(last))) {
                  form.setFieldValue(
                    `additional_fields.${component.fieldName}`,
                    Number(e.currentTarget.value.replaceAll(",", "")),
                  );
                }
              } else {
                form.setFieldValue(
                  `additional_fields.${component.fieldName}`,
                  e.currentTarget.value,
                );
              }
            }}
            placeholder={`${component.type === "number" ? "USD" : ""}`}
            className={`font-xs border-1 w-full rounded-md text-xs ${
              inputValue ? "border-blue-900" : "border-gray-300 text-gray-400"
            }`}
          />
        </div>
      </label>
    </div>
  ) : (
    <></>
  );
};

export const AdditionalTextArea = ({
  component,
  form,
}: {
  component: AdditionalComponent;
  form: FormikProps<OpportunityFormValues>;
}) => {
  let inputValue: string = "";
  if (form.values.additional_fields) {
    inputValue = form.values.additional_fields[component.fieldName];
  }

  const inputField = component.component as TextField;
  return !inputField.hidden ? (
    <div key={component.fieldName} className="grid gap-y-2">
      <label key={`label_${component.fieldName}`} className="flex flex-col">
        <div className="text-xs font-semibold">{component.title}</div>
        <div className="relative">
          <textarea
            key={`input_${component.fieldName}`}
            rows={2}
            value={inputValue}
            onWheel={(e) => e.currentTarget.blur()}
            onChange={(e) => {
              form.setFieldValue(
                `additional_fields.${component.fieldName}`,
                e.currentTarget.value,
              );
            }}
            className={`font-xs border-1 w-full rounded-md text-xs ${
              inputValue ? "border-blue-900" : "border-gray-300 text-gray-400"
            }`}
          />
        </div>
      </label>
    </div>
  ) : (
    <></>
  );
};

export const AdditionalDropdown = ({
  component,
  form,
  envString,
  additionComponents,
  hideTitle = false,
}: {
  component: AdditionalComponent;
  form: FormikProps<OpportunityFormValues>;
  envString: string;
  additionComponents: AdditionalComponents | undefined;
  hideTitle?: boolean;
}) => {
  const multipleFields = ["therapeutic_area"];
  const dropdownField = component.component as Dropdown;
  let dropdownValue: string | string[] = "";
  if (form.values.additional_fields) {
    dropdownValue = form.values.additional_fields[component.fieldName];
  }
  const errors: any = form.errors;
  const hasError = errors && errors[component.fieldName];
  return (
    <div
      key={component.fieldName}
      className={`grid gap-y-2 ${hideTitle ? "mt-0" : ""} `}
    >
      <label key={`label_${component.fieldName}`} className="flex flex-col">
        {!hideTitle && (
          <div className="flex">
            <div className="text-xs font-semibold">{component.title}</div>

            {/* Show red * if field is required  */}
            {isRequiredAddtionalField(envString, component.fieldName) && (
              <div className="text-sm font-semibold text-red-600">*</div>
            )}
          </div>
        )}
        <div className="relative">
          <div
            className={classNames(
              "relative h-fit w-full items-center rounded-md",
              hasError
                ? "border-0.5 border border-red-500"
                : dropdownValue
                  ? "border-0.5 border border-blue-900"
                  : "",
            )}
          >
            <Select
              placeholder={`Select ${component.title}`}
              className={`font-sm w-full rounded-md text-xs`}
              onChange={(e: any) => {
                form.setFieldValue(
                  `additional_fields.${component.fieldName}`,
                  isArray(e) ? e.map((item) => item.value) : e?.value,
                );

                //If dropdown is Source or Dealsource  + value = "others" => show Source Detail input
                if (component.fieldName === "source") {
                  additionComponents?.components.forEach(
                    (addField: AdditionalComponent) => {
                      if (addField.fieldName === "source_details") {
                        (addField.component as TextField).hidden =
                          e?.value !== "Others";
                        form.setFieldValue(
                          "additional_fields.source_details",
                          "",
                        );
                      }
                    },
                  );
                }
              }}
              options={
                dropdownField.options?.map((option: string) => {
                  return { label: option, value: option } as Option;
                }) || []
              }
              value={
                dropdownValue
                  ? isArray(dropdownValue)
                    ? dropdownValue.map((item) => ({
                        label: item,
                        value: item,
                      }))
                    : {
                        label: dropdownValue,
                        value: dropdownValue,
                      }
                  : null
              }
              isMulti={multipleFields.includes(component.fieldName)}
              isClearable
              isAdditionalField={true}
            />
          </div>
          <div className={hasError ? "text-2xs text-red-600" : "hidden"}>
            {hasError ? errors[component.fieldName] : ""}
          </div>
        </div>
      </label>
    </div>
  );
};

export const AdditionalSubSector = ({
  component,
  form,
}: {
  component: AdditionalComponent;
  form: FormikProps<OpportunityFormValues>;
}) => {
  let sector: string = form.values.additional_fields
    ? form.values.additional_fields["sector"]
    : null;
  let subSectors: string[] = [];

  //If has sector => get subsector list
  if (sector) {
    subSectors = InvestmentSectorDict[sector] ?? [];
  } else {
    subSectors = [];
  }

  //Current SubSector of current sector
  let currentSubSector = form.values.sector_list?.filter(
    (sub: any) => sub.sector === sector,
  )[0];

  return sector && subSectors?.length > 0 ? (
    <div key={`${component.fieldName}`} className="grid gap-y-2">
      <label key={`${component.fieldName}`} className="flex flex-col text-xs">
        <div className="flex">
          <div className="font-semibold">Subsector</div>
        </div>
        <div className={"mt-3 flex flex-row flex-wrap gap-2"}>
          {subSectors?.map((subSectorName: string) => {
            //Check subSector is existed
            const subSectorIsSelecting = currentSubSector?.childs
              ? currentSubSector.childs.includes(subSectorName)
              : false;
            return (
              <div
                key={subSectorName}
                onClick={() => {
                  if (subSectorIsSelecting) {
                    currentSubSector.childs = currentSubSector.childs.filter(
                      (item: string) => item !== subSectorName,
                    );
                  } else {
                    if (currentSubSector) {
                      currentSubSector.childs = [
                        ...(currentSubSector.childs || []),
                        subSectorName,
                      ];
                    } else {
                      form.values.sector_list = [
                        ...form.values.sector_list,
                        {
                          sector: form.values.additional_fields.sector,
                          childs: [subSectorName],
                        },
                      ];
                    }
                  }
                  form.setFieldValue("sector_list", form.values.sector_list);
                }}
                className={`${
                  subSectorIsSelecting
                    ? "cursor-pointer border-transparent bg-blue-900 text-white"
                    : "border-gray-500"
                } border-1 cursor-pointer rounded-full border px-4 py-2 text-xs font-medium`}
              >
                {subSectorName}
              </div>
            );
          })}
        </div>
      </label>
    </div>
  ) : (
    <></>
  );
};

export const AdditionalRadioGroup = ({
  component,
  form,
  envString,
}: {
  component: AdditionalComponent;
  form: FormikProps<OpportunityFormValues>;
  envString: string;
}) => {
  let dropdownValue: string = "";
  if (form.values.additional_fields) {
    dropdownValue = form.values.additional_fields[component.fieldName];
  }
  const dropdownField = component.component as Dropdown;
  return (
    <div key={component.fieldName} className="grid gap-y-2">
      <label key={`label_${component.fieldName}`} className="flex flex-col">
        <div className="flex">
          <div className="text-xs font-semibold">{component.title}</div>

          {/* Show red * if field is required  */}
          {isRequiredAddtionalField(envString, component.fieldName) && (
            <div className="text-sm font-semibold text-red-600">*</div>
          )}
        </div>
        <RadioGroup
          defaultValue={dropdownValue}
          onChange={(value: any) => {
            const newValues = {
              ...form.values.additional_fields,
              [`${component.fieldName}`]: value,
            };
            form.setFieldValue(`additional_fields`, newValues);
          }}
          name={component.fieldName}
          className="flex flex-wrap"
        >
          {(dropdownField.options || []).map((fund: any) => (
            <RadioGroup.Option value={fund} key={fund} className="my-2 pr-2">
              {({ checked }) => (
                <span
                  className={`flex items-center gap-x-2 px-4 ${
                    checked
                      ? "cursor-pointer border border-blue-900 bg-blue-100 text-blue-900"
                      : "border-1 border border-gray-500 hover:border-gray-900 hover:bg-gray-200"
                  } cursor-pointer rounded-lg py-2 text-xs font-medium`}
                >
                  {fund}
                </span>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </label>
    </div>
  );
};
